import ScrollMagic from 'scrollmagic';
import { TweenMax, TimelineMax, Linear } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

export default function cardsList() {
  const cards = document.querySelectorAll('.cards');
  const mediaMobile = window.innerWidth < 768;
  if (!cards) return;
  const controller = new ScrollMagic.Controller();

  cards.forEach((el) => {
    const timelineCards = new TimelineMax();
    const cardItems = el.querySelectorAll('.cards__item');

    timelineCards.fromTo(`.cards`, {}, { paddingTop: '25px', ease: Linear.easeNone }, '>');

    for (let i = cardItems.length - 1; i >= 0; i -= 1) {
      if (i > 0) {
        timelineCards.fromTo(
          `.cards__item-${i}`,
          {},
          { y: '65vh', transform: 'scale(1.2)', backgroundColor: '#d9e5ff', ease: Linear.easeNone }
        );
      }

      if (i === 0) {
        timelineCards.fromTo(
          `.cards__item-${i}`,
          {},
          { transform: 'scale(1)', ease: Linear.easeNone }
        );
        cardItems.forEach((elem, idx) => {
          timelineCards.fromTo(
            `.cards__item-${idx}`,
            {},
            {
              display: idx === 0 ? 'flex' : 'none',
              backgroundColor: '#d9e5ff',
              ease: Linear.easeNone
            },
            '<'
          );
        });
      }
    }

    // timelineCards.fromTo(`.cards`, {}, { height: 'auto', ease: Linear.easeNone });

    const durationTime = cardItems.length * 800;
    const durationTimeMobile = cardItems.length * 500;

    new ScrollMagic.Scene({
      triggerElement: '.cards',
      offset: -80,
      triggerHook: 'onLeave',
      duration: mediaMobile ? durationTimeMobile : durationTime
    })
      .setPin('.cards')
      .setTween(timelineCards)
      .addTo(controller);
  });
}
